import { useInputControl } from "@conform-to/react";
import { VariantProps } from "class-variance-authority";
import { Label } from "components/UI/Label.tsx";
import { Paragraph, paragraphVariants } from "components/UI/Paragraph.tsx";
import {
  Checkbox,
  type CheckboxProps,
} from "components/shadcn-ui/checkbox.tsx";
import { Input } from "components/shadcn-ui/input.tsx";
import { Slider } from "components/shadcn-ui/slider.tsx";
import { Switch } from "components/shadcn-ui/switch.tsx";
import { Textarea } from "components/shadcn-ui/textarea.tsx";
import { Euro, Eye, EyeClosed, InfoCircle } from "iconoir-react";
import React, { useId, useRef, useState } from "react";
import PhoneInput, {
  formatPhoneNumberIntl,
  type DefaultInputComponentProps,
} from "react-phone-number-input";
import { cn } from "utils/utils.ts";
import AppTooltip from "../App/AppTooltip.tsx";

export type ListOfErrors = Array<string | null | undefined> | null | undefined;

export function ErrorList({
  id,
  errors,
  className,
}: {
  errors?: ListOfErrors;
  id?: string;
  className?: string;
}) {
  const errorsToRender = errors?.filter(Boolean);
  if (!errorsToRender?.length) return null;
  return (
    <ul
      id={id}
      data-testid={id}
      className={cn("flex flex-col items-start gap-1", className)}
    >
      {errorsToRender.map((e) => (
        <li
          key={e}
          className={paragraphVariants({
            size: "xs",
            fontWeight: "regular",
            className: "text-rouge flex items-center gap-x-2",
          })}
        >
          <InfoCircle className="size-3.5" /> <span>{e}</span>
        </li>
      ))}
    </ul>
  );
}

export function Field({
  labelProps,
  inputProps,
  errors,
  className = "items-start flex flex-col gap-2",
  isEuro = false,
  isTon = false,
  isEmptyValue = false,
  isKilometers = false,
  variantProps = {
    fontWeight: "regular",
    size: "md",
    variant: "secondary",
  },
  icon = null,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  errors?: ListOfErrors;
  className?: string;
  isEuro?: boolean;
  isTon?: boolean;
  isKilometers?: boolean;
  isEmptyValue?: boolean;
  variantProps?: VariantProps<typeof paragraphVariants>;
  icon?: React.ReactNode;
}) {
  const [showPassword, setShowPassord] = React.useState(false);
  const isPassword = inputProps.type === "password";
  const fallbackId = useId();

  const id = inputProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  const { fontWeight, size, variant } = variantProps;

  return (
    <div className={cn(className, "relative flex flex-col gap-2")}>
      {labelProps.children ? (
        <Label
          htmlFor={id}
          {...labelProps}
          as="label"
          className={cn(
            paragraphVariants({
              variant: "secondary",
              size: "md",
            }),

            "text-start",
            "font-medium",
            labelProps.className,
          )}
        />
      ) : null}
      <div className="relative flex w-full items-center gap-1.5">
        {icon ? (
          <div
            className={`absolute h-full z-50 flex items-center top-0 left-3 shrink-0 ${inputProps.disabled ? "text-gray-300" : "text-gray-500"}`}
          >
            {icon}
          </div>
        ) : null}

        {isEmptyValue ? (
          <div className="absolute right-0 top-0 size-[8px] -translate-y-1/2 translate-x-1/2 rounded-full bg-[#FC5151]" />
        ) : null}
        <div className="relative flex items-center w-full">
          <Input
            id={id}
            aria-invalid={errorId ? true : undefined}
            aria-describedby={errorId}
            {...inputProps}
            className={cn(
              "rounded-[8px] p-[13.5px]",
              inputProps.className,
              paragraphVariants({ variant, size, fontWeight: fontWeight }),
              {
                "pl-11": Boolean(icon),
                "rounded-r-none border-r-0": isPassword,
              },
            )}
            type={showPassword ? "text" : inputProps.type}
          />
          {isPassword ? (
            <ShowPasswordToggle
              showPassword={showPassword}
              setShowPassword={setShowPassord}
            />
          ) : null}
        </div>

        {isEuro ? (
          <Euro
            fontSize={14}
            className="text-midnightblue pointer-events-none absolute inset-y-0 right-0 mr-2 h-full shrink-0"
          />
        ) : null}
        {isTon ? (
          <span className="text-midnightblue pointer-events-none absolute inset-y-0 right-0 mr-2 flex h-full shrink-0 items-center justify-center">
            tonnes
          </span>
        ) : null}

        {isKilometers ? (
          <span className="text-midnightblue pointer-events-none absolute inset-y-0 right-0 mr-2 flex h-full shrink-0 items-center justify-center">
            km
          </span>
        ) : null}
      </div>

      {errorId ? (
        <div className="min-h-[32px] pb-3">
          {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
        </div>
      ) : null}
    </div>
  );
}
export function HiddenField({
  inputProps,
  errors,
}: {
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  errors?: ListOfErrors;
}) {
  const fallbackId = useId();
  const id = inputProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <>
      <input id={id} {...inputProps} />
      {errorId ? (
        <div className="min-h-[32px] px-2 pb-3 pt-0">
          {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
        </div>
      ) : null}
    </>
  );
}

export const PhoneField = ({
  labelProps,
  inputProps,
  errors,
  className = "items-start flex flex-col gap-2",
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps: Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> & {
    onChange?: (value: string) => void;
  };
  errors?: ListOfErrors;
  className?: string;
}) => {
  const fallbackId = useId();
  const id = inputProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  const [phone, setPhone] = useState(
    // @ts-ignore
    formatPhoneNumberIntl(inputProps.defaultValue?.toString() ?? ""),
  );
  const { defaultValue, name, ...otherInputProps } = inputProps;
  return (
    <div className={className}>
      <Label
        htmlFor={id}
        {...labelProps}
        as="label"
        className={cn(
          paragraphVariants({
            variant: "secondary",
            size: "md",
          }),
          "text-start",
          "font-medium",
          labelProps.className,
        )}
      />
      <input type="hidden" name={name} value={phone} />
      {/* <input type="hidden" name={`${inputProps.name}Formatted`} value={phone} /> */}
      <PhoneInput
        defaultCountry="FR"
        // name={`${name}-input`}
        // onChange={(e) => {
        // 	const newValue = e?.toString() ?? '';
        // 	onChange?.(newValue);
        // }}
        // style={{
        //   border: value.length > 7 ? "2px solid green" : "2px solid red",
        // }}
        // name={inputProps.name}
        focusInputOnCountrySelection
        initialValueFormat="national"
        locales={["fr-FR"]}
        autoComplete="tel"
        limitMaxLength
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...(otherInputProps as DefaultInputComponentProps)}
        onChange={(e) => {
          if (e) {
            // @ts-ignore
            setPhone(formatPhoneNumberIntl(e?.toString()));
            inputProps.onChange?.(e?.toString());
          } else {
            setPhone("");
            inputProps.onChange?.("");
          }
        }}
        // @ts-ignore
        value={phone}
        className={cn(
          "active:text-secondary focus-visible:border-teal flex h-10 w-full rounded-[8px] border border-gray-200 bg-white px-4 py-3 text-sm font-medium text-gray-800 ring-0 ring-offset-0 transition-all duration-150 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus:outline-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 active:border-green-500 enabled:hover:border-green-400 enabled:active:border-2 enabled:active:outline-0 enabled:active:ring-0 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-300",
          {
            "read-only:bg-gray-100 read-only:text-gray-300":
              inputProps.readOnly,
          },
        )}
      />
      {errorId ? (
        <div className="min-h-[32px] px-2 pb-3 pt-1">
          {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
        </div>
      ) : null}
    </div>
  );
};

export function TextareaField({
  labelProps,
  textareaProps,
  errors,
  className = "items-start flex flex-col gap-2",
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  errors?: ListOfErrors;
  className?: string;
}) {
  const fallbackId = useId();
  const id = textareaProps.id ?? textareaProps.name ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <div className={className}>
      <Label className="font-medium" htmlFor={id} {...labelProps} as="label" />
      <Textarea
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...textareaProps}
      />
      {errorId ? (
        <div className="min-h-[32px] px-2 pb-3 pt-1">
          {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
        </div>
      ) : null}
    </div>
  );
}

export function CheckboxField({
  labelProps,
  buttonProps,
  errors,
  className = "items-start flex flex-col gap-2",
}: {
  labelProps: JSX.IntrinsicElements["label"];
  buttonProps: CheckboxProps & {
    form: string;
    value?: string;
    name: string;
  };
  errors?: ListOfErrors;
  className?: string;
}) {
  const fallbackId = useId();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { key, defaultChecked, ...checkboxProps } = buttonProps;
  const checkedValue = buttonProps.value ?? "on";
  // To emulate native events that Conform listen to:
  // See https://conform.guide/integrations
  const input = useInputControl({
    formId: buttonProps.form,
    name: buttonProps.name,
    initialValue: defaultChecked ? checkedValue : undefined,
  });
  const id = buttonProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <div className={className}>
      <div className="flex items-start gap-2">
        <Checkbox
          {...checkboxProps}
          id={id}
          ref={buttonRef}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          checked={input.value === checkedValue}
          onCheckedChange={(state) => {
            input?.change(state.valueOf() ? checkedValue : "");
            buttonProps.onCheckedChange?.(state);
          }}
          onFocus={(event) => {
            input?.focus();
            buttonProps.onFocus?.(event);
          }}
          onBlur={(event) => {
            input?.blur();
            buttonProps.onBlur?.(event);
          }}
          type="button"
        />
        <Label
          htmlFor={id}
          {...labelProps}
          as="label"
          size="M"
          className={cn(
            "cursor-pointer peer-data-[state=checked]:text-green-600 peer-data-[state=checked]:hover:text-green-700",
            {
              "text-gray-400": checkboxProps.disabled && !checkboxProps.checked,
              "text-gray-300": checkboxProps.disabled && checkboxProps.checked,
              "cursor-not-allowed": checkboxProps.disabled,
            },
            labelProps.className,
          )}
        />
      </div>
      {errorId ? (
        <div className="px-4 pb-3 pt-1">
          {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
        </div>
      ) : null}
    </div>
  );
}
export function ToggleField({
  labelProps,
  buttonProps,
  errors,
  className = "items-start flex flex-col gap-2",
}: {
  labelProps: JSX.IntrinsicElements["label"];
  buttonProps: CheckboxProps & {
    form: string;
    value?: string;
    name: string;
  };
  errors?: ListOfErrors;
  className?: string;
}) {
  const fallbackId = useId();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { key, defaultChecked, ...checkboxProps } = buttonProps;
  const checkedValue = buttonProps.value ?? "on";
  // To emulate native events that Conform listen to:
  // See https://conform.guide/integrations
  const input = useInputControl({
    formId: buttonProps.form,
    name: buttonProps.name,
    initialValue: defaultChecked ? checkedValue : undefined,
  });
  const id = buttonProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <div className={className}>
      <div className="flex items-center gap-2">
        <Switch
          {...checkboxProps}
          id={id}
          ref={buttonRef}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          checked={input.value === checkedValue}
          onCheckedChange={(state) => {
            input?.change(state.valueOf() ? checkedValue : "");
            buttonProps.onCheckedChange?.(state);
          }}
          onFocus={(event) => {
            input?.focus();
            buttonProps.onFocus?.(event);
          }}
          onBlur={(event) => {
            input?.blur();
            buttonProps.onBlur?.(event);
          }}
          type="button"
        />
        <Label
          htmlFor={id}
          as="label"
          {...labelProps}
          className={cn(labelProps.className, "cursor-pointer")}
        />
      </div>
      {errorId ? (
        <div className="px-4 pb-3 pt-1">
          {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
        </div>
      ) : null}
    </div>
  );
}

const ShowPasswordToggle = ({
  showPassword,
  setShowPassword,
}: {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <AppTooltip
      className="flex-shrink"
      triggerComponent={
        <button
          value="show-password"
          type="button"
          onClick={() => setShowPassword((oldShowPassword) => !oldShowPassword)}
          aria-label={showPassword ? "hide password" : "show password"}
          className="border-iron ml-auto flex cursor-pointer items-center justify-center border-2 bg-white px-2.5 py-1.5"
        >
          {showPassword ? (
            <Eye className="size-6 shrink-0" aria-hidden="true" />
          ) : (
            <EyeClosed className="size-6 shrink-0" aria-hidden="true" />
          )}
        </button>
      }
      content={
        <div className="flex max-w-[28ch] flex-col gap-2">
          <Paragraph size="md" fontWeight={"bold"}>
            {`${showPassword ? "Masquer" : "Afficher"} le mot de passe`}
          </Paragraph>
          {/* <Paragraph size="md">{wrapperDescription}</Paragraph> */}
        </div>
      }
    />
  );
};

export function SliderComponent({
  max,
  step,
  labelProps,
  errors,
  onChange,
  value,
  min = 0,
  displayEndLabel,
}: {
  max: number;
  min?: number;
  step: number;

  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  errors?: ListOfErrors;
  onChange?: (value: number[]) => void;
  value?: number[];
  displayEndLabel?: React.ReactNode;
}) {
  const fallbackId = useId();
  const id = fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <div className="my-1 flex flex-row items-center gap-2">
      <Label
        htmlFor={id}
        {...labelProps}
        as="label"
        className="shrink-0 cursor-pointer text-start"
      />
      <Slider
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        id={id}
        value={value}
        onValueChange={(newValues) => {
          onChange?.(newValues ?? 0);
        }}
        max={max}
        min={min}
        step={step}
      />
      {displayEndLabel ? (
        <div className="shrink-0">{displayEndLabel}</div>
      ) : null}
    </div>
  );
}
