import {
  FloatingArrow,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import React, { useRef } from "react";

const AppTooltip = ({
  content,
  triggerComponent,
  className = "flex-shrink-0",
}: {
  className?: string;
  triggerComponent: React.ReactNode;
  content: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom",

    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      arrow({ element: arrowRef }),
      flip({
        fallbackAxisSideDirection: "start",
      }),
      shift(),
    ],
  });

  // Event listeners to change the open state
  const hover = useHover(context, { move: true });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, { role: "tooltip" });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return (
    <>
      <div
        className={className}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {triggerComponent}
      </div>
      <FloatingPortal>
        {isOpen ? (
          <div
            className="bg-secondaryDarker z-50 mt-4 flex flex-col gap-2 rounded-[8px] px-4 py-6 text-sm font-normal normal-case text-current text-white"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <FloatingArrow
              className="fill-secondaryDarker [&>path:first-of-type]:stroke-secondfill-secondaryDarker [&>path:last-of-type]:stroke-secondfill-secondaryDarker"
              staticOffset={"15%"}
              height={18}
              width={32}
              ref={arrowRef}
              context={context}
            />
            {content}
          </div>
        ) : null}
      </FloatingPortal>
    </>
  );
};

export default AppTooltip;
